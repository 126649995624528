<template>
  <b-card-code>
    <b-row>
      <b-col sm="4">
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            :to="'/portal/admin/auxiliary-add'"
          >
            Agregar Auxiliares
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            @click="download()"
          >
            Exportar a Excel
          </b-button>
        </b-button-group>
      </b-col>
      <b-col
        sm="6"
        class="text-right"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="loading"
          @click="syncldap"
        >
          Sincronizar AD
        </b-button>
      </b-col>
      <b-col sm="2">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Buscar"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="syncMessage">
      <b-col>
        <b-alert
          :variant="alertVariant"
          show
        >
          <div class="alert-body">
            <span><strong>Sincronizar AD:</strong> {{ syncMessage }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="rows"
      :fixed-header="true"
      max-height="500px"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status.status'">
          <b-badge :variant="statusVariant(props.row.status.status)">
            {{ props.row.status.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="ListIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="'/portal/admin/auxiliary-edit/' + props.row.auxId">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>
              <b-dropdown-item :to="'/portal/admin/auxiliary-approvers/'+ props.row.auxId">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Aprobadores</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>


        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem, BButtonGroup, BAlert
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { downloadExcel } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'AuxiliaryApproval',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardCode,
    BButtonGroup,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      searching: false,
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: 'Código Auxiliar',
          field: 'auxCode',
        },
        {
          label: 'Centros de Costo',
          field: 'costCentersx.centro',
        },
        {
          label: 'Cuenta Contable',
          field: 'accountId',
        },
        {
          label: 'Clase',
          field: 'classesF.class',
        },
        {
          label: 'Tipo',
          field: 'auxType.type',
        },
        {
          label: 'Descripción',
          field: 'auxDescription',
        },
        {
          label: 'Estado',
          field: 'status.status',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      rowsFiltered: [],
      statuses: [
        { idStatus: 'A', status: 'Activo' },
        { idStatus: 'C', status: 'Cerrado' },
        { idStatus: 'D', status: 'Cargado' },
        { idStatus: 'I', status: 'Inactivo' },
      ],
      types: [
        {
          typeId: 'N',
          type: 'Numérico'
        },
        {
          typeId: 'P',
          type: 'Porcentual'
        }
      ],
      classes: [
        {
          classId: 'A',
          class: 'Automatico'
        },
        {
          classId: 'M',
          class: 'Manual'
        }
      ],
      searchTerm: '',
      accounts: [],
      costCenters: [],
      syncMessage: '',
      alertVariant: 'primary'
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Inactivo: 'light-secondary',
        Activo: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
     this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/auxiliaries/all`)
        const statuses = this.$store.getters['catalog/allStatuses']
        const types = this.types
        const classesD = this.classes

        const costCenters = await this.$http.get(`${environment.uri}/ccenters/all`)
        this.costCenters = costCenters.data

        if (resp && resp.data) {
          this.rows = resp.data.map(item => {
            const status = statuses.find(s => s.idStatus === item.status)
            const auxType = types.find(s => s.typeId === item.auxType)
            const classesF = classesD.find(s => s.classId === item.auxClass)
            const costCentersx = costCenters.data.find(s => s.idCentro === item.centerId)
            return {
              ...item,
              auxType,
              classesF,
              costCentersx,
              status,
            }
          }).sort((a, b) => ((a.idGerencia > b.idGerencia) ? 1 : -1))
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async syncldap(){
      try {
        const resp = await this.$http.get(`${environment.uri}/users/membership/approvers`)
        this.syncMessage = resp.data.message
        this.alertVariant = 'primary'
      } catch (err) {
        console.error(err)
        this.syncMessage = err.response.data
        this.alertVariant = 'danger'
      } finally {
        this.loading = false
      }

    },
    currency(value) {
      return `$${this.$options.filters.currency(value)}`
    },
    date(value) {
      return this.$options.filters.date(value)
    },
    download() {
      const items = this.rows.map(item => ({
        auxCode: (item.auxCode || ''),
        accountId: (item.accountId || ''),
        auxClass: (item.classesF.class || ''),
        auxType:(item.auxType.type || ''),
        auxDescription: (item.auxDescription || ''),
        status: (item.status.status || ''),
      }))
      console.log('Excel: ', items)
      downloadExcel(items, [
        'Código',
        'Cuenta Contable',
        'Clase',
        'Tipo',
        'Descripción',
        'Estado',
      ], 'auxiliaryList.csv')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
